//const apiUrl = "http://localhost:5000/api"; // URL de l'API backend
//const apiUrl = "https://15.237.113.189:443/api"; // URL de l'API backend
const apiUrl = "https://zombieswartelegram.com/api"; // URL de l'API backend

//const apiUrl = "http://15.237.113.189:5000/api"; // URL de l'API backend

// Créer un joueur (POST)
export const createPlayer = async (player) => {
  try {
    const response = await fetch(`${apiUrl}/players`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(player),
    });

    const data = await response.json();

    console.log("data : ", data);
    return data;
  } catch (error) {
    console.error("Error creating player:", error);
    throw error;
  }
};

// Récupérer un joueur (GET)
export const getPlayer = async (id) => {
  try {
    const response = await fetch(`${apiUrl}/players/${id}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching player:", error);
    throw error;
  }
};

// Mettre à jour les pièces du joueur (PUT)
export const updateCoins = async (id, coins) => {
  try {
    const response = await fetch(`${apiUrl}/player/${id}/coins`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ coins }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating coins:", error);
    throw error;
  }
};

// Récupérer toutes les améliorations (GET)
export const getUpgrades = async () => {
  try {
    const response = await fetch(`${apiUrl}/upgrades`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching upgrades:", error);
    throw error;
  }
};

// Acheter une amélioration (POST)
export const purchaseUpgrade = async ({ userId, upgradeId }) => {
  try {
    const response = await fetch(`${apiUrl}/player/upgrades`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, upgradeId }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error purchasing upgrade:", error);
    throw error;
  }
};

export const getInvites = async (userId) => {
  try {
    // Appel de l'API backend pour récupérer les invitations du joueur
    const response = await fetch(`${apiUrl}/player/invites/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch invites: ${response.statusText}`);
    }

    // Retourne les invitations en format JSON
    const invites = await response.json();
    return invites.results;
  } catch (error) {
    console.error("Error fetching invites:", error);
    throw error;
  }
};

export const getPlayerUpgrades = async (userId) => {
  try {
    console.log("userId : ", userId);
    // Appelle l'API backend pour récupérer les upgrades du joueur
    const response = await fetch(`${apiUrl}/player/upgrades/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch player upgrades: ${response.statusText}`
      );
    }

    // Retourne les données JSON si la requête est réussie
    const upgrades = await response.json();

    return upgrades;
  } catch (error) {
    console.error("Error fetching player upgrades:", error);
    throw error;
  }
};

// Mettre à jour les points de zombies du joueur (PUT)
export const updateZombiePoints = async (userId, points) => {
  try {
    const response = await fetch(`${apiUrl}/zombies/${userId}/points`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ points }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating zombie points:", error);
    throw error;
  }
};

export const generateInviteLink = async () => {
  try {
    console.log("go create invite");
    const response = await fetch(`${apiUrl}/create-invite`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        chat_id: "@ZombiesWarBot",
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating zombie points:", error);
    throw error;
  }
};

// Ajouter un ami (POST)
export const addFriend = async (referrerId, playerId) => {
  try {
    const response = await fetch(`${apiUrl}/addFriend`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ referrerId, playerId }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error adding friend:", error);
    throw error;
  }
};

// Récupérer la liste d'amis (GET)
export const getFriends = async (playerId) => {
  try {
    const response = await fetch(`${apiUrl}/friends/${playerId}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching friends:", error);
    throw error;
  }
};
