import React, { useState, useEffect, useRef, useContext } from "react";
import Invoke from "./components/Invoke";
import Upgrade from "./components/Upgrade";
import Friends from "./components/FriendsAndShare";
import Invade from "./components/Invade";
import "./App.css";
import AirdropScreen from "./components/AirdropScreen";
import Header from "./components/Header";
import Menu from "./components/Menu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loadingImage from "./assets/zombieWaiting.png";
import { PlayerContext } from "./PlayerContext";
import { createPlayer, getPlayer } from "./api";

const LOADING_TIMER = 2000;

function App() {
  const [activeScreen, setActiveScreen] = useState("Invoke");
  const [username, setUsername] = useState("");
  const [debugConsole, setDebugConsole] = useState("");

  const telegramUserRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const { setCoins, setPlayerId, setPlayerUpgrades, setZombieNumber } =
    useContext(PlayerContext);

  // Fonction pour rendre l'écran actif
  const renderScreen = () => {
    switch (activeScreen) {
      case "Upgrade":
        return <Upgrade />;
      case "Friends":
        return <Friends />;
      case "Invade":
        return <Invade />;
      case "AirdropScreen":
        return <AirdropScreen />;
      case "Invoke":
      default:
        return <Invoke playerId={telegramUserRef.current?.id} />;
    }
  };

  // Fonction pour initialiser le joueur et les données associées
  const initPlayer = async (user) => {
    try {
      let player = await getPlayer(user.current.id);

      if (player.message === "Player not found") {
        const playerData = {
          ...user.current,
          zombie_number: 0,
          coins: 0,
        };

        player = await createPlayer(playerData);
      }

      setPlayerId(player.id);
      setCoins(player.coins);
      setZombieNumber(player.zombie_number);
    } catch (error) {
      setDebugConsole("player :" + user.current.id + "error : " + error);

      console.error("Error initializing player:", error);
    }
  };

  // Effet pour initialiser les données et gérer le chargement
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), LOADING_TIMER);

    // Vérifiez si l'API Telegram Web App est disponible
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();

      const user = window.Telegram.WebApp.initDataUnsafe?.user;

      if (user) {
        telegramUserRef.current = {
          id: user.id,
          is_bot: user.is_bot,
          first_name: user.first_name,
          last_name: user.last_name,
          username: user.username,
          language_code: user.language_code,
          photo_url: user.photo_url,
        };
      } else {
        telegramUserRef.current = {
          id: 1679785312,
          is_bot: false,
          first_name: "Vaz",
          last_name: "Azd",
          username: "Alaz",
          language_code: "en",
          photo_url: "",
        };
      }
    }
    //setDebugConsole("telegramUserRef :" + JSON.stringify(telegramUserRef));

    setUsername(telegramUserRef.current.first_name);
    initPlayer(telegramUserRef);

    return () => clearTimeout(timer); // Nettoyage du timer
  }, [setCoins, setPlayerId, setPlayerUpgrades]);

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-content">
          <img src={loadingImage} alt="Loading" />
          <p className="loading">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      {debugConsole && <div className="console">{debugConsole}</div>}
      <Header username={username} />
      <div className="screen">{renderScreen()}</div>
      <Menu setActiveScreen={setActiveScreen} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        toastStyle={{ backgroundColor: "#182f35", color: "white" }}
      />
    </div>
  );
}

export default App;
