import React, { useState, useEffect } from "react";
import { GiVillage, GiRaiseZombie, GiUpgrade } from "react-icons/gi";
import { IconContext } from "react-icons";
import { FaUserFriends, FaParachuteBox } from "react-icons/fa";
import './Menu.css';  // Assurez-vous d'importer le fichier CSS

const Menu = ({ setActiveScreen }) => {
  const [selectedMenu, setSelectedMenu] = useState("Invoke");

  const handleMenuClick = (screen) => {
    setSelectedMenu(screen);
    setActiveScreen(screen);
  };

  useEffect(() => {
    setSelectedMenu("Invoke");
  }, []);

  return (
    <div className="menu">
      <IconContext.Provider value={{ color: "white", size: "50px" }}>
        <button
          className={selectedMenu === "Invoke" ? "selected" : ""}
          onClick={() => handleMenuClick("Invoke")}
        >
          <GiRaiseZombie />
        </button>
        <button
          className={selectedMenu === "Upgrade" ? "selected" : ""}
          onClick={() => handleMenuClick("Upgrade")}
        >
          <GiUpgrade />
        </button>
        <button
          className={selectedMenu === "Invade" ? "selected" : ""}
          onClick={() => handleMenuClick("Invade")}
        >
          <GiVillage />
        </button>
        <button
          className={selectedMenu === "Friends" ? "selected" : ""}
          onClick={() => handleMenuClick("Friends")}
        >
          <FaUserFriends />
        </button>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: "#9698FF", size: "50px" }}>
        <button
          className={selectedMenu === "AirdropScreen" ? "selected" : ""}
          onClick={() => handleMenuClick("AirdropScreen")}
        >
          <FaParachuteBox />
        </button>
      </IconContext.Provider>
    </div>
  );
};

export default Menu;
