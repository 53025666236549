import React, { useState, useContext, useEffect } from "react";
import "./Upgrade.css";
import { FaCoins } from "react-icons/fa";
import zombie1 from "../assets/zombie1.png";
import { IconContext } from "react-icons";
import { toast } from "react-toastify";
import { PlayerContext } from "../PlayerContext";
import { getUpgrades, purchaseUpgrade } from "../api";

const Upgrade = () => {
  const [showPopin, setShowPopin] = useState(false);
  const [selectedUpgrade, setSelectedUpgrade] = useState(null);
  const { coins, setCoins, playerId, playerUpgrades, setPlayerUpgrades } =
    useContext(PlayerContext);
  const [upgrades, setUpgrades] = useState([]);

  useEffect(() => {
    const fetchUpgrades = async () => {
      try {
        const upgrades = await getUpgrades();
        setUpgrades(upgrades);
      } catch (error) {
        console.error("Error fetching upgrades:", error);
      }
    };

    fetchUpgrades();
  }, []);

  const openPopin = (upgrade) => {
    setSelectedUpgrade(upgrade);
    setShowPopin(true);
  };

  const closePopin = () => {
    setShowPopin(false);
  };

  const getUpgradeLevel = (upgradeId) => {
    const playerUpgrade = playerUpgrades.find(
      (u) => u.upgrade_id === upgradeId
    );
    return playerUpgrade ? playerUpgrade.level : 0;
  };

  const calculateUpgradeCost = (baseCost, level) => {
    return Math.round(baseCost * Math.pow(1.2, level));
  };

  const buyUpgrade = async () => {
    const currentLevel = getUpgradeLevel(selectedUpgrade.upgrade_id);
    const upgradeCost = calculateUpgradeCost(
      selectedUpgrade.cost,
      currentLevel
    );

    if (coins >= upgradeCost) {
      try {
        // Effectuer l'achat de l'upgrade
        await purchaseUpgrade({
          userId: playerId,
          upgradeId: selectedUpgrade.upgrade_id,
        });

        // Mise à jour des pièces
        setCoins(coins - upgradeCost);

        // Log pour vérifier les données
        console.log("Selected Upgrade ID:", selectedUpgrade.upgrade_id);
        console.log("Player Upgrades:", playerUpgrades);

        // Mise à jour des upgrades du joueur
        setPlayerUpgrades((actualUpgrades) => {
          console.log("selectedUpgrade : ", selectedUpgrade);
          // Si l'amélioration existe déjà, on incrémente son niveau
          if (selectedUpgrade.upgrade_level > 0) {
            const updatedUpgrades = [...actualUpgrades, selectedUpgrade];
            console.log("AMELIORATION EXISTANTE");
            return updatedUpgrades;
          } else {
            // Si l'amélioration n'existe pas, on l'ajoute avec le niveau initialisé à 1
            console.log("AMELIORATION NOUVELLE");
            return [...actualUpgrades, { ...selectedUpgrade, level: 1 }];
          }
        });

        toast.success("Upgrade bought!");

        // Fermer la popin après l'achat
        closePopin();
      } catch (error) {
        toast.error("Error purchasing upgrade, try later!");
        console.error("Error purchasing upgrade:", error);
      }

      setSelectedUpgrade(null);
    } else {
      toast.error("Not enough coins!");
    }
  };

  // Fonction pour obtenir le prochain upgrade non acheté d'un type spécifique
  const getNextAvailableUpgrade = (upgradesByType) => {
    // Trier les améliorations par niveau
    const sortedUpgrades = upgradesByType.sort(
      (a, b) => a.upgrade_level - b.upgrade_level
    );

    // Parcourir les améliorations et trouver la première amélioration qui n'a pas encore été achetée
    for (const upgrade of sortedUpgrades) {
      const playerUpgrade = playerUpgrades.find(
        (u) => u.upgrade_id === upgrade.upgrade_id
      );

      if (!playerUpgrade || playerUpgrade.level < upgrade.upgrade_level) {
        return upgrade; // Retourner la première amélioration non achetée
      }
    }

    // Si toutes les améliorations ont été achetées, retourner null ou la dernière amélioration
    return null;
  };

  const upgradeTypes = [...new Set(upgrades.map((u) => u.upgrade_title))];

  return (
    <div className="upgrade-screen">
      <div className="upgrades-container">
        {upgradeTypes.map((type) => {
          const upgradesByType = upgrades.filter(
            (upgrade) => upgrade.upgrade_title === type
          );
          const nextUpgrade = getNextAvailableUpgrade(upgradesByType);

          // Si un upgrade a déjà été acheté (par type === upgrade_title), alors on doit
          // mettre le isPurchased à true
          if (!nextUpgrade) {
            return <p key={type}>All upgrades purchased for {type}!</p>;
          }

          const isPurchased = nextUpgrade.upgrade_level > 1;

          return (
            <button
              key={nextUpgrade.upgrade_id}
              className={`upgrade-item ${
                isPurchased ? "purchased" : "not-purchased"
              }`}
              onClick={() => openPopin(nextUpgrade)}
            >
              <div className="upgrade-image">
                <img src={zombie1} alt="upgrade" />
              </div>
              <div className="upgrade-details">
                <h2>
                  {nextUpgrade.upgrade_title} (Level {nextUpgrade.upgrade_level}
                  )
                </h2>
                <p>{nextUpgrade.upgrade_description}</p>
                <div className="upgrade-cost">
                  <IconContext.Provider
                    value={{ color: "#4FFF57", size: "20px" }}
                  >
                    <div className="coins">
                      <FaCoins />{" "}
                      {calculateUpgradeCost(
                        nextUpgrade.cost,
                        getUpgradeLevel(nextUpgrade.upgrade_id)
                      )}
                    </div>
                  </IconContext.Provider>
                </div>
              </div>
              <div
                className={`mask ${isPurchased ? "" : "not-purchased"}`}
              ></div>
            </button>
          );
        })}
      </div>

      {showPopin && selectedUpgrade && (
        <div className="popin-overlay">
          <div className="popin">
            <div className="popin-content">
              <button className="close-button" onClick={closePopin}>
                X
              </button>
              <h2>{selectedUpgrade.upgrade_title}</h2>
              <p>Bonus : {selectedUpgrade.upgrade_description}</p>
              <p>
                Next Cost : <FaCoins />{" "}
                {calculateUpgradeCost(
                  selectedUpgrade.cost,
                  getUpgradeLevel(selectedUpgrade.upgrade_id)
                )}
              </p>
              <button className="buy-button" onClick={buyUpgrade}>
                Buy
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Upgrade;
