import React, { createContext, useEffect, useState } from "react";
import { getPlayerUpgrades } from "./api"; // Importez la fonction ici

// Créez le contexte
export const PlayerContext = createContext();

// Fournisseur de contexte
export const PlayerProvider = ({ children }) => {
  // Déclarez l'état pour l'ID du joueur
  const [playerId, setPlayerId] = useState();
  const [coins, setCoins] = useState(0);
  const [playerUpgrades, setPlayerUpgrades] = useState([]);
  const [zombieNumber, setZombieNumber] = useState(0);

  useEffect(() => {
    const fetchPlayerUpgrades = async () => {
      if (playerId) {
        try {
          const upgrades = await getPlayerUpgrades(playerId);

          setPlayerUpgrades(upgrades);
        } catch (error) {
          console.error("Error fetching player upgrades:", error);
        }
      }
    };

    fetchPlayerUpgrades();
  }, [playerId]);

  return (
    <PlayerContext.Provider
      value={{
        coins,
        setCoins,
        playerId,
        setPlayerId,
        playerUpgrades,
        setPlayerUpgrades,
        zombieNumber,
        setZombieNumber,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
