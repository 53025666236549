import React, { useContext } from "react";
import { FaCoins } from "react-icons/fa";
import { GiShamblingZombie } from "react-icons/gi";
import { IconContext } from "react-icons";
import { PlayerContext } from "src/PlayerContext";

const Header = ({ username }) => {
  const { coins, zombieNumber, playerId } = useContext(PlayerContext);

  // Function to get the current value based on whether initial load is complete
  const getCurrentCoins = () => coins;
  const getCurrentZombieNumber = () => zombieNumber;

  return (
    <div className="header">
      <div>{playerId}</div>

      <div className="username">{username}</div>
      <IconContext.Provider value={{ color: "#4FFF57", size: "20px" }}>
        <div className="coins">
          {getCurrentCoins()} <FaCoins />
        </div>
      </IconContext.Provider>
      <IconContext.Provider value={{ size: "20px" }}>
        <div className="zombies">
          {getCurrentZombieNumber()} <GiShamblingZombie />
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default Header;
