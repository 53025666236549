import React from 'react';
import loadingImage from '../assets/zombieWaiting.png';

const AirdropScreen = () => {
    return (
        <div className="airdrop-screen">
            <h1>Airdrop</h1>
            <h2>Coming soon...</h2>

            <img src={loadingImage} alt="Loading" />
            {/* Contenu spécifique pour l'écran AirdropScreen */}
        </div>
    );
};

export default AirdropScreen;
