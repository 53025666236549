// src/components/Invade.js
import React, { useState, useContext } from "react";
import village1 from "../assets/village1.png";
import village2 from "../assets/village2.png";
import village3 from "../assets/village3.png";
import village4 from "../assets/village4.png";
import village5 from "../assets/village5.png";
import village6 from "../assets/village6.png";
import village7 from "../assets/village7.png";
import village8 from "../assets/village8.png";
import "./Invade.css";
import { GiShamblingZombie } from "react-icons/gi";
import { FaCoins } from "react-icons/fa";
import { IconContext } from "react-icons";
import { toast } from "react-toastify";
import { PlayerContext } from "../PlayerContext";
import { updateZombiePoints, updateCoins } from "src/api";

const villages = [
  { id: 1, image: village1, zombiesRequired: 10, coinsReward: 50 },
  { id: 2, image: village2, zombiesRequired: 20, coinsReward: 100 },
  { id: 3, image: village3, zombiesRequired: 30, coinsReward: 150 },
  { id: 4, image: village4, zombiesRequired: 40, coinsReward: 200 },
  { id: 5, image: village5, zombiesRequired: 50, coinsReward: 250 },
  { id: 6, image: village6, zombiesRequired: 60, coinsReward: 300 },
  { id: 7, image: village7, zombiesRequired: 70, coinsReward: 350 },
  { id: 8, image: village8, zombiesRequired: 80, coinsReward: 400 },
];

const Invade = () => {
  const { zombieNumber, setZombieNumber } = useContext(PlayerContext);
  const { playerId, coins, setCoins, playerUpgrades } =
    useContext(PlayerContext);
  const [selectedVillage, setSelectedVillage] = useState(null);

  const handleVillageClick = (village) => {
    setSelectedVillage(village);
  };

  const closePopin = () => {
    setSelectedVillage(null);
  };

  const handleAttack = async () => {
    if (!selectedVillage) {
      toast.error("No village selected.");
      return;
    }

    if (zombieNumber >= selectedVillage.zombiesRequired) {
      const newZombieNumber = zombieNumber - selectedVillage.zombiesRequired;
      let bonusCoins = 0;
      const upgradeBonuses = {
        1: 1,
        10: 2,
        11: 3,
        12: 4,
        13: 5,
        14: 6,
        15: 7,
        16: 8,
        17: 9,
        18: 10,
        19: 11,
      };

      playerUpgrades.forEach((upgrade) => {
        if (upgradeBonuses[upgrade.upgrade_id]) {
          bonusCoins = Math.max(bonusCoins, upgradeBonuses[upgrade.upgrade_id]);
        }
      });

      console.log("bonusCoins : ", bonusCoins);
      const newCoins = coins + selectedVillage.coinsReward + bonusCoins;

      try {
        // Mise à jour des zombies et des pièces en IndexedDB
        await updateZombiePoints(playerId, newZombieNumber);
        await updateCoins(playerId, newCoins);

        // Mise à jour des états locaux
        setZombieNumber(newZombieNumber);
        setCoins(newCoins);

        toast.success(
          `You have conquered the village and earned ${selectedVillage.coinsReward} coins!`
        );
      } catch (error) {
        console.error("Error updating data:", error);
        toast.error("Failed to update data.");
      }
    } else {
      toast.error("Not enough zombies to attack the village!");
    }
    closePopin();
  };

  return (
    <div className="invade-screen">
      <div className="villages-container">
        {villages.map((village) => (
          <div
            key={village.id}
            className="village-item"
            onClick={() => handleVillageClick(village)}
          >
            <img
              src={village.image}
              alt={`Village ${village.id}`}
              className="village-image"
            />
            <div className="village-info">
              <h2>Village {village.id}</h2>
              <p>
                Required: {village.zombiesRequired} <GiShamblingZombie />
              </p>
              <p>
                Reward: {village.coinsReward}{" "}
                <IconContext.Provider
                  value={{ color: "#4FFF57", size: "20px" }}
                >
                  <FaCoins />
                </IconContext.Provider>
              </p>
            </div>
          </div>
        ))}
      </div>

      {selectedVillage && (
        <div className="popin-overlay">
          <div className="popin">
            <h2>Attack Village {selectedVillage.id}</h2>
            <img
              src={selectedVillage.image}
              alt={`Village ${selectedVillage.id}`}
              className="popin-image"
            />
            <p>
              Required: {selectedVillage.zombiesRequired} <GiShamblingZombie />
            </p>
            <p>
              Reward: {selectedVillage.coinsReward}{" "}
              <IconContext.Provider value={{ color: "#4FFF57", size: "20px" }}>
                <FaCoins />
              </IconContext.Provider>
            </p>
            <p>
              Your Zombies: {zombieNumber} <GiShamblingZombie />
            </p>
            <button className="closePopin" onClick={closePopin}>
              Close
            </button>
            <button className="attackPopin" onClick={handleAttack}>
              Attack
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invade;
