import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { FaClipboard, FaCoins } from "react-icons/fa";
import { getInvites } from "../api.js"; // Importez les fonctions API nécessaires
import "./FriendsAndShare.css";
import { PlayerContext } from "src/PlayerContext";
import { IconContext } from "react-icons";

const FriendsAndShare = () => {
  const { playerId } = useContext(PlayerContext);

  const [friends, setFriends] = useState([]);

  useEffect(() => {
    // Récupérez la liste des amis invités par le joueur
    const fetchFriends = async () => {
      try {
        console.log("fetchFriends");
        const response = await getInvites(playerId);
        console.log("response : ", response);

        setFriends(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des amis:", error);
      }
    };

    if (playerId) {
      fetchFriends();
    }
  }, [playerId]);

  const handleCopyLink = () => {
    if (playerId) {
      const telegramLink = `https://t.me/ZombiesWarBot?start=${playerId}`;
      navigator.clipboard
        .writeText(telegramLink)
        .then(() => toast.success("Link copied!"))
        .catch(() => toast.error("Error when link copied"));
    }
  };

  return (
    <div className="invite-friends-screen">
      <h1>Invite friends and share</h1>

      <div className="friends-list-container">
        <div className="friends-list">
          <h2>Friends list</h2>
          {friends && friends.length > 0 ? (
            <ul>
              {friends.map((friend) => (
                <li key={friend.referrer_username}>
                  {friend.referrer_username}
                  <IconContext.Provider
                    value={{ color: "#4FFF57", size: "20px" }}
                  >
                    <div className="coins">
                      {friend.player_coins} <FaCoins />
                    </div>
                  </IconContext.Provider>
                </li>
              ))}
            </ul>
          ) : (
            <p>You haven't invited any friends yet.</p>
          )}
        </div>
      </div>

      <button onClick={handleCopyLink} className="copy-link-button">
        <FaClipboard /> Copy the link
      </button>
    </div>
  );
};

export default FriendsAndShare;
