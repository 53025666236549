import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Ajouter le polyfill Buffer
import { Buffer } from "buffer";
import { PlayerProvider } from "./PlayerContext";
window.Buffer = Buffer;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PlayerProvider>
    <App />
  </PlayerProvider>
);

reportWebVitals();
