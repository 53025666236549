// src/components/Invoke.js
import React, { useState, useContext, useEffect } from "react";
import { ProgressBar } from "react-bootstrap"; // Importez la ProgressBar de react-bootstrap
import zombieStats from "./zombieStats";
import zombie1 from "../assets/zombie1.png";
import zombie2 from "../assets/zombie2.png";
import zombie3 from "../assets/zombie3.png";
import zombie4 from "../assets/zombie4.png";
import zombie5 from "../assets/zombie5.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Importez les styles bootstrap

import "./Invoke.css";
import { PlayerContext } from "../PlayerContext"; // Corrigé le chemin d'importation
import { updateZombiePoints } from "src/api";

const DAILY_CLICK_LIMIT = 100;
const RESET_INTERVAL = 1 * 60 * 60 * 1000; // 1 heure en millisecondes

const Invoke = ({ playerId }) => {
  const { playerUpgrades } = useContext(PlayerContext);
  const { zombieNumber, setZombieNumber } = useContext(PlayerContext);
  const [plusOnes, setPlusOnes] = useState([]);
  const [dailyClicks, setDailyClicks] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [plusZombieNumber, setPlusZombieNumber] = useState(1);

  const zombieImages = [zombie1, zombie2, zombie3, zombie4, zombie5];

  useEffect(() => {
    const storedClicks = localStorage.getItem(`dailyClicks-${playerId}`);
    const storedLastClickTime = localStorage.getItem(
      `lastClickTime-${playerId}`
    );
    const now = new Date();

    if (storedLastClickTime) {
      const lastClickTime = new Date(parseInt(storedLastClickTime, 10));
      if (now - lastClickTime >= RESET_INTERVAL) {
        // Réinitialiser les clics quotidiens après l'intervalle de réinitialisation
        setDailyClicks(0);
        localStorage.removeItem(`dailyClicks-${playerId}`);
        localStorage.removeItem(`lastClickTime-${playerId}`);
      } else {
        setDailyClicks(parseInt(storedClicks, 10) || 0);
        updateTimeRemaining(lastClickTime);
        const intervalId = setInterval(
          () => updateTimeRemaining(lastClickTime),
          1000
        );
        return () => clearInterval(intervalId);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(`dailyClicks-${playerId}`, dailyClicks);
  }, [dailyClicks, playerId]);

  const updateTimeRemaining = (lastClickTime) => {
    const resetTime = new Date(lastClickTime.getTime() + RESET_INTERVAL);
    const timeDiff = resetTime - new Date();

    if (timeDiff <= 0) {
      // Réinitialiser le compteur de clics et enlever les éléments liés au temps
      setDailyClicks(0);
      setTimeRemaining(null);
      localStorage.removeItem(`resetTime-${playerId}`);
    } else {
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    }
  };

  const handleClick = () => {
    if (dailyClicks >= DAILY_CLICK_LIMIT) {
      if (!timeRemaining) {
        const resetTime = new Date(Date.now() + RESET_INTERVAL);
        localStorage.setItem(`resetTime-${playerId}`, resetTime.getTime());
        updateTimeRemaining(resetTime);
        const intervalId = setInterval(
          () => updateTimeRemaining(resetTime),
          1000
        );
        return () => clearInterval(intervalId);
      }
      return;
    }

    let bonusZombies = 0;
    const upgradeBonuses = {
      2: 1,
      20: 2,
      21: 3,
      22: 4,
      23: 5,
      24: 6,
      25: 7,
      26: 8,
      27: 9,
      28: 10,
      29: 11,
    };

    playerUpgrades.forEach((upgrade) => {
      if (upgradeBonuses[upgrade.upgrade_id]) {
        bonusZombies = Math.max(
          bonusZombies,
          upgradeBonuses[upgrade.upgrade_id]
        );
      }
    });

    setPlusZombieNumber(1 + bonusZombies);
    const newZombieNumber = zombieNumber + 1 + bonusZombies;
    setZombieNumber(newZombieNumber);
    setDailyClicks(dailyClicks + 1);
    localStorage.setItem(`lastClickTime-${playerId}`, Date.now().toString());

    updateZombiePoints(playerId, newZombieNumber).catch((error) => {
      console.error("Error updating zombie points:", error);
    });

    const id = Math.random().toString(36).substr(2, 9);
    const randomLeft = Math.random() * 40 - 20;

    const newPlusOne = {
      id,
      style: { left: `${50 + randomLeft}%` },
      image: zombieImages[0],
    };
    setPlusOnes((prevPlusOnes) => [...prevPlusOnes, newPlusOne]);

    setTimeout(() => {
      setPlusOnes((currentPlusOnes) =>
        currentPlusOnes.filter((plusOne) => plusOne.id !== id)
      );
    }, 5000);
  };

  const computeZombiesToDisplay = (zombieNumber) => {
    const zombies = Array(8).fill(0);
    let remainingZombies = zombieNumber;

    const thresholds = [
      1, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000,
    ];

    for (let i = 7; i >= 0; i--) {
      if (remainingZombies >= thresholds[i]) {
        zombies[i] = Math.floor(remainingZombies / thresholds[i]);
        remainingZombies %= thresholds[i];
      }
    }

    return zombies;
  };

  const renderZombiesList = () => {
    const zombiesToDisplay = [];
    for (let i = 0; i < 5; i++) {
      zombiesToDisplay.push(
        <span className="zombie" key={zombieStats[i].id}>
          <img
            src={zombieImages[zombieStats[i].id - 1]}
            alt={`Zombie ${zombieStats[i].id}`}
          />
          <span className="zombiesNumber">
            {computeZombiesToDisplay(zombieNumber)[i]}
          </span>
        </span>
      );
    }

    return zombiesToDisplay;
  };

  return (
    <div className="invoke-screen">
      <div className="zombies-list">{renderZombiesList()}</div>
      <div className="zombie-container" onClick={handleClick}>
        {plusOnes.map((plusOne) => (
          <div key={plusOne.id} className="plus-one" style={plusOne.style}>
            <img src={plusOne.image} alt="Zombie" />+{plusZombieNumber}
          </div>
        ))}
        <img src={zombieImages[0]} alt="Zombie" className="zombie-image" />
      </div>
      <div className="clicks-info">
        <div className="clicks-text">
          {dailyClicks}/{DAILY_CLICK_LIMIT} Invoked
        </div>
        <ProgressBar
          now={dailyClicks}
          max={DAILY_CLICK_LIMIT}
          variant="success"
          style={{ height: "30px" }}
        />
        {timeRemaining && <div className="time-remaining">{timeRemaining}</div>}
      </div>
    </div>
  );
};

export default Invoke;
